<template>
  <div class="mt-10">
    <v-col md="12" v-if="snackbar">
      <v-snackbar
        class="toastSnack"
        v-model="snackbar"
        color="red"
        :top="true"
        :multi-line="false"
        :vertical="false"
      >
        {{ `Contrato não encontrado` }}
        <v-btn dark text @click="snackbar = false">X</v-btn>
      </v-snackbar>
    </v-col>
    <div class="text-title">
      <p>Abaixo, segue opções para realizar o Download dos Documentos:</p>
    </div>
    <v-card class="card-box mx-auto" outlined>
      <div class="text-box">
        <p class="text-content">
          Contrato de Gestão de Pagamento + Anexo ao Contrato
          <br />de Prestação de Serviços de Pagamento
        </p>
      </div>
      <div class="text-box-mobile">
        <p class="text-content">
          Contrato de Gestão de Pagamento + Anexo ao Contrato de Prestação de Serviços de Pagamento
        </p>
      </div>
      <v-col md="4" offset-md="12" class="colSolicitation">
        <button class="btnNewRequest" @click="handleClick">Baixar Contrato</button>
      </v-col>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "myContracts",
  data: () => ({
    snackbar: false
  }),
  computed: {
    ...mapGetters({
      info: "User/getInfo",
      error: "Registration/getError"
    })
  },
  watch: {
    error(value) {
      if (value) {
        this.snackbar = true;
      }
    }
  },
  methods: {
    ...mapActions({
      downloadContractByUuid: "Registration/downloadContractByUuid"
    }),
    ...mapMutations({
      clearError: "Registration/CLEAR_ERROR"
    }),
    async forceFileDownload() {
      const url = await this.downloadContractByUuid(this.info.login);
      if (this.error) return;
      window.open(url);
    },
    handleClick() {
      this.forceFileDownload();
    }
  },
  created() {
    this.clearError();
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/commons/myContracts.scss";
</style>
